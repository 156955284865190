import * as React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import { useAuth } from './../context/AuthContext';

import './../styles/navbar.scss';

const Header = () => {  
  const { authenticated } = useAuth();

  const data = useStaticQuery(graphql`
  query BrandingQuery {
    strapiBranding {
      favicon {
        localFile {
          childImageSharp {
            gatsbyImageData(height: 30)
          }
        }
      }
      logo {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, height: 70)
          }
        }
      }
    }
    strapiNavbar {
      nav_link {
        text
        url
      }
    }
  }
  
  `);

  const isActive = ({ isCurrent }) => ({ className: `nav-link ${isCurrent ? 'active' : ''}` });
  
  const ExactNavLink = props => (
    <Link getProps={isActive} {...props} />
  )

  // const image = 
  return (
    <>
      <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container-fluid">
          <Link className="navbar-brand" to="/">
            <GatsbyImage image={data.strapiBranding.logo.localFile.childImageSharp.gatsbyImageData} />
          </Link>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mb-2 me-auto mb-lg-0">
              { data.strapiNavbar.nav_link.map(link => <>
                <li class="nav-item">
                  <ExactNavLink to={link.url}>{link.text}</ExactNavLink>
                  <div class='nav-underline'></div>
                </li>
              </>)}
            </ul>
            <ul class="navbar-nav">
              <li class="nav-link-button nav-button">
                <Link to="/members"> <button type="button" class="btn btn-outline-primary">Members</button></Link>
              </li>
              {
                authenticated ? 
                <li class="nav-link-button nav-button">
                  <Link to="/members/logout"> <button type="button" class="btn btn-primary">Logout</button></Link>
                </li>
                : <li class="nav-link-button nav-button">
                  <Link to="/contact"> <button type="button" class="btn btn-primary">Contact Us</button></Link>
                </li>
              }
              
            </ul>
          </div>
        </div>
      </nav>
      
    </>
  )
}


export default Header;

