import * as React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faLinkedin, faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';

import './../styles/footer.scss';

const faHash = {
  email: faEnvelope,
  phone: faPhone,
  twitter: faTwitter,
  linkedin: faLinkedin,
  facebook: faFacebook,
  instagram: faInstagram,
  youtube: faYoutube
};

const Footer = ({ children }) => {

  const data = useStaticQuery(graphql`
    query FooterQuery {
      strapiFooter {
        contact_description
        contact_heading
        navigation_heading
        social_heading
        footer_navigation_link {
          display_text
          url
        }
        footer_social_link {
          display_text
          icon
          url
        }
      }
    }
  `);

  return (
    <>
      <div id="footer">
        <div class="footer-container">
          <div class="footer-column">
            {/* social */}

            <p class="footer-heading">{data.strapiFooter.social_heading}</p>
            <div class="footer-social">
              {data.strapiFooter.footer_social_link.map(sl => <>
                <p class="footer-social-link">
                  <a href={(sl.icon === 'email' ? 'mailto:' : '') + sl.url}>
                    <FontAwesomeIcon icon={faHash[sl.icon]} />
                    {sl.display_text}
                  </a>
                </p>
              </>)}
            </div>

            {/* contact form */}
            {/* <p class="footer-heading">{data.strapiFooter.contact_heading}</p>
            <p class="footer-description">{data.strapiFooter.contact_description}</p>

            <form>
            <div class="form-group footer-topline-formgroup">
              <input type="text" class="form-control" id="contact-name" placeholder="Name" />
              <input type="email" class="form-control" id="contact-email" placeholder="Email" />
            </div>
            <div class="form-group">
              <input type="text" class="form-control" id="contact-subject" placeholder="Subject" />
            </div>
            <div class="form-group">
            <textarea class="form-control" id="contact-message" rows="3" placeholder="Message"></textarea>
            </div>
            <button type="submit" class="btn btn-light">Submit</button>
          </form> */}

          </div>

          

          <div class="footer-column">
            {/* navigation */}
            <p class="footer-heading">{data.strapiFooter.navigation_heading}</p>
              <div class="footer-navigation">

                {data.strapiFooter.footer_navigation_link.map(link => <>
                  <Link to={link.url}>{link.display_text}</Link>
                </>)}
                
              </div>
            </div>
            

          <div class="footer-divider" />

          <div class="footer-second-row">
            <p class="footer-copyight">© Copyright {new Date().getFullYear()} Faculty of Medical Coaches</p>
            <p class="footer-attr">
              Site design by&nbsp;
              <a href="https://www.emilyshen.co.uk" class="">Emily&nbsp;</a>
              and&nbsp;
              <a href="https://www.cameronrobey.co.uk" class="">Cameron</a>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer;
